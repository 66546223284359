export const DYNAMIC_PROPERTIES = {
  occSubscriptionProductType: 'occ_subscriptionProductType',
};

export const SUBSCRIPTION_PRODUCT_TYPES = {
  subscriptionItem: 'subscriptionItem',
  nonSubscriptionItem: 'nonSubscriptionItem',
};

export const CR_PRODUCT_TYPES = ['PTO', 'ATO'];

export const SUBSCRIPTION_SOFTWARE_MSI_ITEM_TYPES = ['SUBSV'];
