import { getCurrentPageId, getGlobalContext, getSite, getsiteSettings, isAuthenticated, getCurrentProfileId, getCurrentOrder } from '@oracle-cx-commerce/commerce-utils/selector';
import { getCheapestShippingForRegion } from './helpers/default-shipping-helper';

export const getShippingMethods = state => state.availableShippingMethods || [];

const getTaxSettings = state => getsiteSettings(state).msiTaxSettings || {};

export const getCurrentPageIdAsProps = store => ({ pageId: getCurrentPageId(store) || undefined });

export const getLocale = state => getGlobalContext(state).locale || 'en';

export const getDefaulShippingCountryId = state => getSite(state).defaultShippingCountryId || 'US';

export const getAudienceRepository = state => state.audienceRepository || {};

export const getAudienceMembership = state => getAudienceRepository(state).audienceMembership || ['GeoLocationUS'];

export const getDefaultShippingForAudience = (state, msiShippable) => getCheapestShippingForRegion(getShippingMethods(state), state, msiShippable) || { ranges: [{}] };

export const getDefaultTaxRate = state => getTaxSettings(state).defaultTaxRateForUS || 0.06;

export const getSiteUrl = state => getSite(state)?.productionURL;

export const getFeatureFlags = state => getsiteSettings(state).oeSiteFeatures || {};

export const getFeatureFlagById = (state, id) => getFeatureFlags(state)[id];

export const isGuest = state => !isAuthenticated(state) && getCurrentProfileId(state) === 'anonymous';

export const getOrderDynamicProperties = (state, order = getCurrentOrder(state)) => order.dynamicProperties || [];

export const getSpecificOrderDynamicPropertyValue = (state, { id, order }) => getOrderDynamicProperties(state, order).find(property => property.id === id)?.value || null;

export const getShipFromAddress = state => getSite(state).shipFromAddress || {};

export const isProduction = state => !!getGlobalContext(state).appServerURL?.includes('c1prd') || false;
