import { getDynamicProperty } from '../commerceItem';

const subscriptionKey = 'occ_subscriptionProductType';
const subscriptionValue = 'subscriptionItem';

export const isSubscription = product => {
  let isSub = false;
  if (product?.childItems?.length) {
    product.childItems.forEach(item => {
      const isArray = Array.isArray(item.dynamicProperties);
      const subscriptionInfo = isArray ? item.dynamicProperties?.find(prop => prop.id === subscriptionKey) : '';
      if (subscriptionInfo?.value === subscriptionValue) {
        isSub = true;
      }
    });
  }

  return isSub;
};

export const isOnlySubscription = product => product?.childItems?.every(item => getDynamicProperty(item, subscriptionKey) === subscriptionValue);
