import { DEFAULT_GEOLOCATION, GEOLOCATION_MAPPING } from '../../../constants/geolocation';

export const getGeolocation = (audiences, isGuest) => {
  if (!isGuest || !audiences) {
    return DEFAULT_GEOLOCATION;
  }
  const audienceArray = Array.isArray(audiences) ? audiences : Object.values(audiences);

  for (const audience of audienceArray) {
    if (GEOLOCATION_MAPPING[audience]) {
      return GEOLOCATION_MAPPING[audience];
    }
  }

  return DEFAULT_GEOLOCATION;
};
