import { isProduction } from '../../../plugins/selectors';
import { resolveBoolean } from '../../common/boolean';

export const conditionallyDisableReactDevtools = state => {
  const shouldEnableDevtools = resolveBoolean(localStorage.getItem('msiEnableDevtools')) || !isProduction(state);
  if (typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ !== 'object' || shouldEnableDevtools) {
    return;
  }

  for (const prop in window.__REACT_DEVTOOLS_GLOBAL_HOOK__) {
    if (prop === 'renderers') {
      window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] = new Map();
      continue;
    }
    window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] = typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] === 'function' ? Function.prototype : null;
  }
};
